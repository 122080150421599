import { Buffer } from "buffer";

const stringify = {
  Buffer,
  toBase64(value: string) {
    return this.Buffer.from(value).toString("base64");
  },
};

export default stringify;
