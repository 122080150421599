import { handleServerError } from "../../utils/error";
import Api from "../api";
import ApiPath from "../api/api.path";
import ApiConstant from '../api/api.const';
import stringify from '../../utils/stringify';

const signIn = async (data: any) => {
  try {
    const resp = await Api({
      headers: {
        "role": "admin",
        "Authorization": `Bearer ${stringify.toBase64(data.username + ":" + data.password)}`
      },
      url: ApiPath.ADMIN_SIGN_IN,
      method: "POST",
      data,
    });

    const token = resp?.data?.token;
    const status = resp?.data.err;

    if (!status && token) {
      localStorage.setItem(ApiConstant.ACCESS_TOKEN, token);
    }

    return token;
  } catch (error) {
    console.log(error);

    handleServerError(error);
  }
};

const getMe = async () => {
  try {
    const resp = await Api({
      headers: {
        "role": "admin"
      },
      url: ApiPath.GET_ME,
      method: "GET",
    });

    const data = resp?.data?.user;
    const status = resp?.data.err;

    if (!status && data) {
      return data;
    }

    return {};
  } catch (error) {
    // handleServerError(error);
  }
}

const changePassword = async (id: number, payload: any) => {
  try {
    const resp = await Api({
      headers: {
        "role": "admin"
      },
      url: ApiPath.ADMIN_CHANGE_PASSWORD + "/" + id,
      method: "PATCH",
      data: payload
    });

    const data = resp?.data?.user;
    const status = resp?.data.error;

    if (!status && data) {
      return data;
    }

    return { error: status };
  } catch (error) {
    handleServerError(error);
  }
}

const logout = async () => {
  try {
    await Api({
      headers: {
        "role": "admin"
      },
      url: ApiPath.ADMIN_SIGN_OUT,
      method: "DELETE",
    });

  } catch (error) {
    handleServerError(error);
  }
}

const AuthRepo = {
  signIn,
  getMe,
  changePassword,
  logout
};

export default AuthRepo;
