import React from "react";

import { ADMIN_ROUTES } from "./route";

const AuthLoadableComponent = React.lazy(
  () => import("../pages/public/AuthContainer")
);

const AdminDashboardLoadableComponent = React.lazy(
  () => import("../pages/admin/Dashboard")
);

const AdviceDashboardLoadableComponent = React.lazy(
  () => import("../pages/admin/AdviceDashboard")
);

const PrescriptionDashboardLoadableComponent = React.lazy(
  () => import("../pages/admin/PrescriptionDashboard")
);

const SymptomDashboardLoadableComponent = React.lazy(
  () => import("../pages/admin/SymptomDashboard")
);

const MedicineDashboardLoadableComponent = React.lazy(
  () => import("../pages/admin/MedicineDashboard")
);

const UserDashboardLoadableComponent = React.lazy(
  () => import("../pages/admin/UserDetails")
);

const SymptomDetailLoadableComponent = React.lazy(
  () => import("../pages/admin/SymptomDetails")
);

const MedicineDetailLoadableComponent = React.lazy(
  () => import("../pages/admin/MedicineDetails")
);

const RouteConfig = [
  // admin routes
  {
    path: ADMIN_ROUTES.DASHBOARD,
    component: AdminDashboardLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.ADVICE,
    component: AdviceDashboardLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.PRESCRIPTION,
    component: PrescriptionDashboardLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.SYMPTOM_DETAIL,
    component: SymptomDetailLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.SYMPTOMS,
    component: SymptomDashboardLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.MEDICINE_DETAIL,
    component: MedicineDetailLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.MEDICINE,
    component: MedicineDashboardLoadableComponent,
    exact: true,
    auth: true,
  },
  {
    path: ADMIN_ROUTES.USER,
    component: UserDashboardLoadableComponent,
    exact: true,
    auth: true,
  },

  {
    path: ADMIN_ROUTES.HOME,
    component: AuthLoadableComponent,
    exact: false,
    auth: false,
  },
];

export default RouteConfig;
