import { notification } from "antd";
import i18n from "../translation";

export const handleServerError = (error: any) => {
  const message = error.response?.data?.error[0]?.message;
  notification.error({
    message: i18n.t("Failed"),
    description: message || "Something went wrong.",
  });
};
