export const ADMIN_ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  ADVICE: "/advices",
  PRESCRIPTION: "/prescription",
  SYMPTOMS: "/symptom",
  MEDICINE: "/medicine",
  USER: "/user/:id",
  SYMPTOM_DETAIL: "/symptom/:id",
  MEDICINE_DETAIL: "/medicine/:id",
};

export const PUBLIC_ROUTES = {
  NOT_FOUND: "*",
};