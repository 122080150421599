import React from "react";
import styles from "../../../styles/Loading.module.scss";

const Loading: React.FC = () => {
  return (
    <div className="flex justify-center items-center min-w-screen min-h-screen">
      <div className={styles.spinner} />
    </div>
  );
};

export default Loading;
