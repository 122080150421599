import axios, { AxiosInstance } from 'axios';

const Api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT
})

Api.interceptors.request.use((config) => {
  if (
    !config.headers ||
    config.headers.Authorization
  ) {
    return config;
  }

  const token = localStorage.getItem(process.env.REACT_APP_STORAGE_ACCESS_KEY || "");
  const tempToken = localStorage.getItem(process.env.REACT_APP_TEMP_STORAGE_ACCESS_KEY || "");
  config.headers.Authorization = `Bearer ${token || tempToken}`;

  return config;
});

Api.interceptors.response.use(
  (res) => {
    return res.data;
  },
  function (err) {
    const status = err?.response?.status;
    if (status === 401) {
      localStorage.removeItem(process.env.REACT_APP_STORAGE_ACCESS_KEY || "");
      localStorage.removeItem(process.env.REACT_APP_TEMP_STORAGE_ACCESS_KEY || "");
    }
    return Promise.reject(err);
  }
);

export default Api;
