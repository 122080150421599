import { FC } from "react";
import { Redirect, Route } from "react-router-dom";
import { ADMIN_ROUTES } from "../route";
import ApiConstant from "../../service/api/api.const";

interface AuthRouteProps {
  component: any;
  exact: boolean | undefined;
  path: string;
}

const AuthRoute: FC<AuthRouteProps> = ({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  component: Component = () => {},
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (localStorage.getItem(ApiConstant.ACCESS_TOKEN)) {
          return <Component {...props} />;
        }

        return (
          <Redirect
            to={{
              pathname: ADMIN_ROUTES.HOME,
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
};

export default AuthRoute;
