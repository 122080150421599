// Common
const GET_ME = "/admin/tokens";

// Admin
const ADMIN_SIGN_IN = "/admin/login";
const ADMIN_SIGN_OUT = "/admin/logout";
const ADMIN_CHANGE_PASSWORD = "/admin/change-password";


const ADMIN_LIST_USER = "/users"
const ADMIN_LIST_ADVICE = "/advice"
const ADMIN_LIST_PRESCRIPTION = "/record-medicine-prescription"
const ADMIN_LIST_SYMPTOM = "/symptoms"
const ADMIN_LIST_MEDICINE = "/medicine"
const ADMIN_LIST_RECORD = "/records"


const ApiPath = {
  GET_ME,
  ADMIN_SIGN_IN,
  ADMIN_CHANGE_PASSWORD,
  ADMIN_LIST_USER,
  ADMIN_LIST_ADVICE,
  ADMIN_LIST_PRESCRIPTION,
  ADMIN_LIST_SYMPTOM,
  ADMIN_LIST_MEDICINE,
  ADMIN_SIGN_OUT,
  ADMIN_LIST_RECORD
};

export default ApiPath;
