import "./App.css";
import Routers from "./routes";
import { ConfigProvider } from "antd";
import { Colors } from "./constants/color";
import DocumentMeta from "react-document-meta";

function App() {
  const meta = {
    title: "MyHopy Admin",
  };

  return (
    <DocumentMeta {...meta}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: Colors.PRIMARY,
              defaultBg: Colors.PRIMARY,
              defaultColor: "white",
              defaultBorderColor: Colors.PRIMARY,
              colorPrimaryHover: Colors.PRIMARY,
              colorPrimaryActive: Colors.PRIMARY,
              lineWidth: 1.5,
            },
          },
        }}
      >
        <Routers />
      </ConfigProvider>
    </DocumentMeta>
  );
}

export default App;
