import { FC, Suspense, useEffect } from "react";

import { HashRouter, Route, Switch } from "react-router-dom";
import RouteConfig from "./config";
import AuthRoute from "./AuthRoute";
import Loading from "../pages/public/Loading";
import { useRecoilState } from "recoil";
import AdminStore from "../service/admin/admin.recoil";
import AuthRepo from "../service/auth/auth.repo";

const Routers: FC = () => {
  const [currentUser, setCurrentUser] = useRecoilState(AdminStore.info);

  useEffect(() => {
    if (!currentUser) {
      AuthRepo.getMe().then((data) => {
        setCurrentUser(data);
      });
    }
  }, [currentUser, setCurrentUser]);

  return (
    <HashRouter>
      <Suspense fallback={<Loading />}>
        <Switch>
          {RouteConfig.map((route, index) => {
            const RenderComponent = route.auth ? (
              <AuthRoute
                key={index}
                component={route.component}
                exact={route.exact}
                path={route.path}
              />
            ) : (
              <Route
                exact={route.exact}
                path={route.path}
                component={route.component}
                key={index}
              />
            );
            return RenderComponent;
          })}
        </Switch>
      </Suspense>
    </HashRouter>
  );
};

export default Routers;
