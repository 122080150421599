import { atom } from 'recoil';
import { IAdmin } from './admin.type';

const info = atom<IAdmin | null>({
  key: "infoState",
  default: null,
});

const AdminStore = {
  info,
}

export default AdminStore;
